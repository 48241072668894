import { ApolloClient, InMemoryCache, useQuery } from '@apollo/client';
import { GetServerSideProps } from 'next';
import { useRouter } from 'next/router';
import * as React from 'react';

import { Loading } from '@/imports/ui/chakra/components/Loading';
import { MilleFeuille, MilleFeuilleMain } from '@/layouts/MilleFeuille';
import { FooterRender, PageRender } from '@/modules/website-builder';
import { CURRENT_USER_ROOT_URL, HOME_PAGE_AND_TENANT } from '@/queries';
import { CurrentUserRootUrl } from '@/queries/graphql-types/CurrentUserRootUrl';
import { HomePageAndTenant } from '@/queries/graphql-types/HomePageAndTenant';

type IndexPageProps = {
  content: React.ComponentProps<typeof PageRender>['content'] | null;
  tenant: React.ComponentProps<typeof FooterRender>['tenant'] | null;
};

// Here we basically are checking if the association has set up a custom home
// page. If they are we render it, otherwise we redirect to the right place

export const getServerSideProps: GetServerSideProps<IndexPageProps> = async (
  context
) => {
  try {
    const client = new ApolloClient({
      ssrMode: true,
      uri: process.env.NEXT_PUBLIC_APOLLO_HTTP_URI,
      cache: new InMemoryCache(),
    });

    const query = await client.query<HomePageAndTenant>({
      query: HOME_PAGE_AND_TENANT,
      context: {
        headers: {
          tenant_domain: context.req?.headers.tenant_domain,
          authorization: `Bearer ${context.req.cookies['accounts%3AaccessToken']}`, // This is sort of dangerous but it should be fine since we dont have CORS enabled on the next.js server
        },
      },
      fetchPolicy: 'no-cache',
    });

    if (!query.data.homePage?.content || !query.data.tenant.result) {
      return {
        props: {
          content: null,
          tenant: null,
        },
      };
    }

    return {
      props: {
        content: query.data.homePage.content,
        tenant: query.data.tenant.result,
      },
    };
  } catch (error) {
    if (error?.networkError?.statusCode === 422) {
      return {
        notFound: true,
      };
    }

    return {
      props: {
        content: null,
        tenant: null,
      },
    };
  }
};

const Redirect: React.FC = () => {
  const router = useRouter();

  const { data, loading } = useQuery<CurrentUserRootUrl>(CURRENT_USER_ROOT_URL);

  const rootUrl = data?.tenant?.result?.currentUserLandingPageUrl;

  React.useEffect(() => {
    if (router.isReady && !loading) {
      if (rootUrl) {
        router.replace({ pathname: rootUrl, query: router.query });
      } else {
        router.replace({
          pathname: '/login',
          query: {
            redirect: router.asPath,
          },
        });
      }
    }
  }, [loading, router, rootUrl]);

  return <Loading />;
};

const IndexPage: React.FC<IndexPageProps> = (props) => {
  if (props.content && props.tenant) {
    return (
      <MilleFeuille>
        <MilleFeuilleMain>
          <PageRender content={props.content} />
        </MilleFeuilleMain>
        <FooterRender tenant={props.tenant} />
      </MilleFeuille>
    );
  }

  return <Redirect />;
};

export default IndexPage;
